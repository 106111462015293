import React, { createContext, ReactNode, useContext, useEffect, useLayoutEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { baseURL } from 'constants/baseURL';
import { useGenericAuth } from 'hooks/useGenericAuth';

const Api = axios.create({
    baseURL,
    timeout: 120000,
    headers: {
        'content-type': 'application/json',
    },
});

const ServiceApiContext = createContext({} as typeof Api);

export function ServiceApiProvider({ children }: { children: ReactNode }): JSX.Element {
    const { resetSession, accessToken, isAuthenticated, isFirstTime, setIsFirstTime } = useGenericAuth();
    const timeoutHandled = useRef(true);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const interceptorRequestRef = useRef<null | number>();
    const interceptorresponseref = useRef<null | number>();

    useLayoutEffect(() => {
        // Caso haja interceptador, significa que havia um token ativo.
        // Se trocou o troken, removemos o interceptador com aquele token  e sua referência.
        if (typeof interceptorRequestRef.current === 'number') {
            Api.interceptors.request.eject(interceptorRequestRef.current);
            interceptorRequestRef.current = null;
        }

        // Caso trocou o token e ele é válido, criamos um novo interceptador.
        if (isAuthenticated) {
            queryClient.setDefaultOptions({
                queries: {
                    refetchOnWindowFocus: false,
                    retry: false,
                    enabled: true,
                },
            });

            interceptorRequestRef.current = Api.interceptors.request.use((config) => {
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        ...(accessToken && {
                            common: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }),
                    },
                };
            });
        }
    }, [accessToken]);

    useLayoutEffect(() => {
        // Caso haja interceptador, significa que trocou o valor de isFirstTime.
        // Removemos o interceptador com sua referência.
        if (typeof interceptorresponseref.current === 'number') {
            Api.interceptors.response.eject(interceptorresponseref.current);
            interceptorresponseref.current = null;
        }

        // Cria um novo interceptor com a referência mais atual de isFirstTime
        interceptorresponseref.current = Api.interceptors.response.use(
            (response: any) => {
                if (isFirstTime) {
                    setIsFirstTime(false);
                }

                return response;
            },
            (error) => {
                const { response, code } = error;

                if (response?.status === 401) {
                    resetSession();
                }

                // Código de erro para quando ocorre o timeout do axios
                if (code && code === 'ECONNABORTED') {
                    // Tratativa utilizada para evitar que a mensagem seja exibida duas vezes,
                    // visto que quando ocorre o timeout pelo axios o método de error é executado duas vezes.
                    if (timeoutHandled.current) {
                        enqueueSnackbar('Tempo limite de consulta esgotado', { variant: 'error', autoHideDuration: 8000 });

                        timeoutHandled.current = false;
                    } else {
                        timeoutHandled.current = true;
                    }
                }

                return Promise.reject(error.response ?? error);
            },
        );
    }, [isFirstTime]);

    return <ServiceApiContext.Provider value={Api}>{children}</ServiceApiContext.Provider>;
}

export const useServiceApiContext = (): typeof Api => useContext(ServiceApiContext);
